import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { CustomLocationsResult } from "../types/customLocationsResultType";

async function fetchRecentCustomLocationsBySearch(query: string) {
  const url = `${TASKS_SERVICE_URL}/v1/locations/custom?query=${query}`;
  const { result: customLocations } = await fetchAPI("GET", url);
  return customLocations as CustomLocationsResult;
}

export function useRecentCustomLocationsBySearch(query: string) {
  return useQuery({
    enabled: !!query && query.length > 2,
    queryKey: ["customlocations", "query", query],
    queryFn: async () => await fetchRecentCustomLocationsBySearch(query),
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
