import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { CustomLocationsResult } from "../types/customLocationsResultType";

async function fetchRecentCustomLocationsForItem(itemNo: string) {
  const url = `${TASKS_SERVICE_URL}/v1/locations/custom/item?itemNo=${itemNo}`;
  const { result: customLocations } = await fetchAPI("GET", url);
  return customLocations as CustomLocationsResult;
}

export function useRecentCustomLocationsForItem(itemNo: string) {
  return useQuery({
    enabled: !!itemNo,
    queryKey: ["customlocations", "item", itemNo],
    queryFn: async () => await fetchRecentCustomLocationsForItem(itemNo),
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
